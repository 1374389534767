<template>
  <div class="news-header">
    <div class="news-header-content main-news">
      <div class="news-header-left">
        <span class="sec-font main-title" dcolor>NOTICIAS</span>
      </div>
      <div class="news-header-right">
        <a class="news-header-search" @click="NewsSearch">
          <iconic name="my_search" />

          <span class="text">Buscar noticia...</span>
        </a>
        <router-link to="/formNews" class="news-header-new" v-if="isAdminNews">
          <iconic name="plus" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import NewsMixing from "./NewsMixing";
  export default {
    mixins: [NewsMixing],
  };
</script>

<style lang="scss">
  .content.main,
  .content.newsList {
    padding: 0 0 $mpadding 0;
  }
  .main-title {
    font-size: 32px;
  }
  .news-header {
    position: fixed;
    top: 65px;
    left: 20px;
    right: 20px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(4px);
    z-index: 2;
    padding: $mpadding/2 $mpadding;
    &-content {
      display: flex;
      justify-content: space-between;
    }
    &-right {
      display: flex;
      align-items: center;
      gap: $mpadding;
    }

    &-new,
    &-search {
      @include Flex(inherit, center, center);
      background-color: $primary_color;
      color: #fff;
      width: $mpadding * 2.5;
      height: $mpadding * 2.5;
      border-radius: 50%;
      cursor: pointer;
      &:hover {
        background-color: $secondary_color;
        color: #fff;
      }
      .text {
        display: none;
      }
    }

    @include screen($pocket_width) {
      &-search {
        display: flex;
        align-items: center;
        gap: $mpadding/1.5;
        transition: all 0.25s ease-in-out;
        cursor: pointer;
        padding: $mpadding/2 $mpadding;
        border-radius: $mradius/2;
        color: inherit;
        width: auto;
        height: auto;
        background-color: inherit;
        &:hover {
          background-color: rgba(87, 87, 87, 0.3);
          text-decoration: none;
        }
        * {
          pointer-events: none;
        }
        .text {
          display: block;
        }
      }
    }

    @media (min-width: 1690px) {
      &-left {
        margin: 0 0 0 $mpadding;
      }
      &-right {
        margin: 0 $mpadding 0 0;
      }
    }
  }
</style>
